import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { RoutePath } from './../../routes';
import Footer from "./component/footer";
import Header from "./component/header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// const InnerHeader = styled('div')(({ theme }) => ({
//   marginTop:'300px'
// }));

export default function AppRoot(props) {
  const { loggedCustomer, isLoggedIn, isSaved } = props;
  return (
    <Box
      sx={{ overflow: 'hidden' }}
    >
      <CssBaseline />
      <Header key={loggedCustomer} loggedCustomer={loggedCustomer} isLoggedIn={isLoggedIn} isSaved={isSaved}/>
      <RoutePath />
      <Footer />
    </Box>
  );
}