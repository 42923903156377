import * as actionType from "./customerActionType";

const initialState = {
  isLoading: false,
  isSaved:false,
  isLoggedIn: sessionStorage.getItem('loggedCustomer') !== null ? true : false,
  loggedCustomer: sessionStorage.getItem('loggedCustomer') !== null ? JSON.parse(sessionStorage.getItem('loggedCustomer')) : {}
};

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.CUSTOMER_FETCH:
            return {
                ...state,
                isLoading: true
            };
        case actionType.CUSTOMER_FETCH_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CUSTOMER_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customer: action.payload ? action.payload.data : {}
            };
        case actionType.CUSTOMER_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.CUSTOMER_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CUSTOMER_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            };
        case actionType.CUSTOMER_LOGIN:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.CUSTOMER_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CUSTOMER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loggedCustomer: action.payload ? action.payload.data : {},
                isLoggedIn: true
            };
        case actionType.CUSTOMER_REGISTER:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.CUSTOMER_REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CUSTOMER_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loggedCustomer: action.payload ? action.payload.data : {},
                isLoggedIn: true
            };
        case actionType.CURRENT_CUSTOMER_FETCH:
            return {
                ...state,
                isLoading: true
            };
        case actionType.CURRENT_CUSTOMER_FETCH_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CURRENT_CUSTOMER_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                currentCustomer: action.payload ? action.payload.data : {},
                loggedCustomer: action.payload ? action.payload.data : {},

            };

        default:
            return state;
    }
}