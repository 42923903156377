export const CREATE_CONTACT_US = "CREATE_CONTACT_US";
export const CREATE_CONTACT_US_FAILURE = "CREATE_CONTACT_US_FAILURE";
export const CREATE_CONTACT_US_SUCCESS = "CREATE_CONTACT_US_SUCCESS";

export const DELETE_CONTACT_US = "DELETE_CONTACT_US";
export const DELETE_CONTACT_US_FAILURE = "DELETE_CONTACT_US_FAILURE";
export const DELETE_CONTACT_US_SUCCESS = "DELETE_CONTACT_US_SUCCESS";

export const GET_CONTACT_US = "GET_CONTACT_US";
export const GET_CONTACT_US_FAILURE = "GET_CONTACT_US_FAILURE";
export const GET_CONTACT_US_SUCCESS = "GET_CONTACT_US_SUCCESS";

export const UPDATE_CONTACT_US = "UPDATE_CONTACT_US";
export const UPDATE_CONTACT_US_FAILURE = "UPDATE_CONTACT_US_FAILURE";
export const UPDATE_CONTACT_US_SUCCESS = "UPDATE_CONTACT_US_SUCCESS";
