import * as actionType from "./propertyActionType";

const initialState = {
  isLoading: false,
  property: [],
  savedProperty: null,
  propertyByTag: []
};

export const propertyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_PROPERTY:
            return {
                ...state,
                isLoading: true
            };
        case actionType.GET_PROPERTY_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.GET_PROPERTY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                properties: action.payload ? action.payload.data : []
            };
        case actionType.GET_PROPERTY_BY_TAG:
            return {
                ...state,
                isLoading: true
            };
        case actionType.GET_PROPERTY_BY_TAG_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.GET_PROPERTY_BY_TAG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                propertyByTag: action.payload ? action.payload.data : []
            };
        case actionType.GET_PROPERTY_BY_ID:
            return {
                ...state,
                isLoading: true
            };
        case actionType.GET_PROPERTY_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.GET_PROPERTY_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                property: action.payload ? action.payload.data : {}
            };
        default:
            return state;
    }
}