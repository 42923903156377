export const CREATE_BOOKING = "CREATE_BOOKING";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_FAILURE = "CONFIRM_BOOKING_FAILURE";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";

export const FETCH_BOOKING = "FETCH_BOOKING";
export const FETCH_BOOKING_FAILURE = "FETCH_BOOKING_FAILURE";
export const FETCH_BOOKING_SUCCESS = "FETCH_BOOKING_SUCCESS";

export const CHECK_BOOKING = "CHECK_BOOKING";
export const CHECK_BOOKING_FAILURE = "CHECK_BOOKING_FAILURE";
export const CHECK_BOOKING_SUCCESS = "CHECK_BOOKING_SUCCESS";

export const SAVE_PROPERTY_FOR_NEXT = "SAVE_PROPERTY_FOR_NEXT";
export const REMOVE_PROPERTY_FOR_NEXT = "REMOVE_PROPERTY_FOR_NEXT";