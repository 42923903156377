export const GET_PROPERTY = "GET_PROPERTY";
export const GET_PROPERTY_FAILURE = "GET_PROPERTY_FAILURE";
export const GET_PROPERTY_SUCCESS = "GET_PROPERTY_SUCCESS";

export const GET_PROPERTY_BY_TAG = "GET_PROPERTY_BY_TAG";
export const GET_PROPERTY_BY_TAG_FAILURE = "GET_PROPERTY_BY_TAG_FAILURE";
export const GET_PROPERTY_BY_TAG_SUCCESS = "GET_PROPERTY_BY_TAG_SUCCESS";

export const GET_PROPERTY_BY_ID = "GET_PROPERTY_BY_ID";
export const GET_PROPERTY_BY_ID_FAILURE = "GET_PROPERTY_BY_ID_FAILURE";
export const GET_PROPERTY_BY_ID_SUCCESS = "GET_PROPERTY_BY_ID_SUCCESS";

export const SAVE_PROPERTY_FOR_NEXT = "SAVE_PROPERTY_FOR_NEXT";